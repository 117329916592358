
  import { defineComponent, computed } from "vue";

  export default defineComponent({
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    setup(props) {
      const buttons = computed(():any[] => {
        const batons = props.data.field.options.buttons.reduce((res, b) => {
          if (typeof(b) === 'function') {
            const btn = b(props.data)
            if (btn) res.push(btn)
          } else {
            res.push(b)
          }
          return res
        },[])
        // console.log({batons})
        return batons
      })
      const dropdownThreshold = props.data.field.options.dropdownThreshold
      const showButtons = buttons.value.length <= dropdownThreshold || 4
      const buttonTarget = (button) => {return button.target || '_self'}
      const buttonLinkType = (button) => (button.linkType || 'to')
      const isStart = props.data.field.options.justification === 'start'
      const justification = isStart ? 'justify-content-start' : 'justify-content-end'
      const btnMargin = isStart ? 'mr-3' : 'ml-3'
      return {
        buttons,
        showButtons,
        buttonTarget,
        buttonLinkType,
        justification,
        btnMargin
      }
    }
  });
